<editor-header *ngIf="canShowEditButtons()" [edm]="_data.edm" [customButtons]="true">
        <span customButtons class="inline" style="margin-left: 10px; margin-top: 6px;">
            <button *ngIf="!_data.edm.editing" type="button" class="btn btn-sm btn-primary" [disabled]="!canSave()" (click)="onSave()">Save</button>
            <button *ngIf="canShowConfirmDelete()" type="button" class="btn-confirm-delete" style="width: 140px; padding: 12px;" (click)="onConfirmDelete()">Confirm Delete</button>
            <button *ngIf="canShowApply" type="button" class="btn btn-sm btn-primary" [disabled]="!canApply()" (click)="onApply()">Apply</button>
            <button *ngIf="canShowBack()" type="button" class="btn btn-sm btn-primary" (click)="onBack()">Back</button>
            <button *ngIf="canCancel()" type="button" class="btn btn-sm btn-primary" (click)="onCancel()">Cancel</button>
        </span>
</editor-header>

<div *ngIf="canShowEditButtons() && canSave()">
    <span class="page-subtitle bold">{{_saveMessage}}</span>
</div>
<span class="alert-danger">{{_validationMessage}}</span>

<div class="row">
    <i *ngIf="_isLoading" class="fa fa-circle-o-notch fa-spin" style="font-size:48px; color: #337ab7; margin-top: 8px; margin-left: 24px;"></i>
</div>

<div *ngIf="canFilterAndView()">
    <div class="material-reported-use-filters-container">

        <div class="material-reported-use-filters-container" style="margin-left: 6px; margin-top: 12px;">

            <div class="material-reported-uses-grid-container" style="margin-left: 8px;">
                <span class="bold item-material-uses-reporting-organization-label">Reporting Organization</span>
                <span class="bold item-material-uses-survey-years-label">Survey Year</span>
                <span class="bold item-material-uses-select-material-label">Survey Material</span>

                <select class="dropdown-toggle text-left background-white survey-list item-material-uses-reporting-organization" style="width: 320px !important;"
                        [(ngModel)]="_filterOrganizationName">
                    <option *ngFor="let name of _organizationNames; let ix = index" class="dropdown" [value]="name">{{name}}</option>
                </select>

                <select class="dropdown-toggle text-left background-white survey-list item-material-uses-survey-years" style="width: 140px !important;" [(ngModel)]="_filterSurveyYear">
                    <option *ngFor="let year of _surveyYears; let ix = index" class="dropdown" [value]="year">{{year}}</option>
                </select>

                <div class="survey-filter-cols item-material-uses-select-material">
                    <div class="input-group">
                        <div class="casnumber-and-rifmid background-white" style="width: 280px;">
                            <span style="text-decoration: none !important; padding-left: 2px; padding-right: 4px; background-color: aliceblue;">{{_selectedCASDisplay}}</span>
                            <a *ngIf="_selectedCASDisplay && _selectedCASDisplay != ''" class="text-pointer remove-x" (click)="clearSelectedMaterial()">X</a>
                        </div>
                    </div>
                    <button type="button" class="btn  btn-primary select-survey-material-button" (click)="onSelectMaterial()">Select Material</button>
                </div>

                <button [disabled]="!canApplyFilter()" class="btn btn-search-image item-material-uses-apply-filter-button" style="font-size: 14px; width: 212px;" type="button" (click)="applyFilter()">Search Reported Uses</button>

                <button *ngIf="canEditReportingOrganization()" type="button" class="inline btn btn-edit-organization item-material-uses-edit-organization" style="margin-left: 4px; width: 259px; color: white !important; font-size: 14px; font-weight: bold !important;" (click)="onEditReportingOrganization()">Edit Selected Organization</button>
                <button *ngIf="canAddReportingOrganization()" type="button" class="inline btn btn-add-organization item-material-uses-add-organization" style="margin-left: 4px; width: 260px; color: white !important;" (click)="onAddReportingOrganization()">Add New Organization</button>

            </div>

        </div>
    </div>
</div>

<div *ngIf="canFilterAndView()">

    <div class="row" style="width: 100%; margin-left: 4px; margin-top: 18px;">
        <div>
            <a *ngIf="canShowNotes()" class="inline bold" (click)="onShowNotes()"> Show All Notes and History for the Selected Material
                <img class="inline" style="margin-left: 4px;" src="assets/images/ExposureReport.png" width="24"/>
            </a>
        </div>
        <span class="inline" style="margin-top: 12px;">
                <span class="bold inline" style="margin-left: 8px;">{{_reportTitle}}</span>
                <button *ngIf="canAddUnreportedMaterial()" type="button" class="inline btn btn-add-geo-area" style="margin-left: 24px; width: 370px; color: white !important;" (click)="onAdd()">Add Survey Data for this unreported Material</button>
        </span>
    </div>

    <div *ngIf="hasUseData()">
        <div style="width: 1620px; border: 2px solid #8B8989;">
            <div>
                <div class="scrollableTableHeader">
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 25px">Edit</span></div>
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 75px">Remove All</span></div>
                    <div class="scrollableTableColumn text-left"><span class="inline" style="width: 240px">Organization</span></div>
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 70px">Survey Year</span></div>
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 70px">MaterialId</span></div>
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 80px">CAS #</span></div>
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 80px">RIFM ID</span></div>

                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 115px">Asia-Pacific</span></div>
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 115px">Europe</span></div>
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 115px">Japan</span></div>
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 115px">North America</span></div>
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 115px">South America</span></div>
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 115px">Other</span></div>
                </div>
                <div style="height: auto; max-height: 410px; width: 1618px; overflow-y:auto;overflow-x: hidden;">
                    <div class="scrollableTableRow" *ngFor="let msu of _surveyReportedUsesByCompanyAndMaterialAndYearEx" [ngStyle]="{ 'background-color': msu.backgroundColor }">
                        <div class="scrollableTableColumn text-center" style="width: 25px"><a class="inline"  (click)="onEdit(msu)">Edit</a></div>
                        <div class="scrollableTableColumn text-center" style="width: 98px"><a class="inline" (click)="onRemove(msu)">Remove All</a></div>
                        <div class="scrollableTableColumn text-left"><span class="inline" style="width: 240px">{{msu.data.organizationName}}</span></div>
                        <div class="scrollableTableColumn text-center"><span class="inline" style="width: 70px">{{msu.data.surveyYear}}</span></div>
                        <div class="scrollableTableColumn text-center"><a class="inline" style="width: 70px" (click)="onSelectMaterialFromList(msu)">{{msu.data.materialId}}</a></div>
                        <div class="scrollableTableColumn text-center"><span class="inline" style="width: 80px">{{msu.data.realCASNumber}}</span></div>
                        <div class="scrollableTableColumn text-center"><span class="inline" style="width: 80px">{{msu.data.rIFMId}}</span></div>
                        <div class="scrollableTableColumn text-right"><span class="inline" style="width: 115px">{{ utilFns.formatNumber(msu.data.asiaPacific, 14,  4) }}</span></div>
                        <div class="scrollableTableColumn text-right"><span class="inline" style="width: 115px">{{ utilFns.formatNumber(msu.data.europe, 14, 4) }}</span></div>
                        <div class="scrollableTableColumn text-right"><span class="inline" style="width: 115px">{{ utilFns.formatNumber(msu.data.japan, 14, 4) }}</span></div>
                        <div class="scrollableTableColumn text-right"><span class="inline" style="width: 115px">{{ utilFns.formatNumber(msu.data.northAmerica, 14, 4) }}</span></div>
                        <div class="scrollableTableColumn text-right"><span class="inline" style="width: 115px">{{ utilFns.formatNumber(msu.data.southAmerica, 14, 4) }}</span></div>
                        <div class="scrollableTableColumn text-right"><span class="inline" style="width: 115px">{{ utilFns.formatNumber(msu.data.other, 14, 4) }}</span></div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>

<material-selector></material-selector>

<div *ngIf="canEdit()">
    <material-reported-use-editor></material-reported-use-editor>
</div>

<div *ngIf="canShowEditReportingOrganizationComponent()">
    <reporting-organization-editor></reporting-organization-editor>
</div>

<div *ngIf="_canShowWorkflowModal">
    <material-workflow-history [material]="_data.selectedMaterial" [activityType]="_workflowActivityType"></material-workflow-history>
</div>
