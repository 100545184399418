import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';

import { EditorService, MolImageService, StateMap, UnitOfWork, UserManager } from '../../services/common';
import { EditManager, LocationFns, UtilFns } from '../../utils/common';

import {Material, TypeMaterialUnit, TypeTSCAClass} from '../../entities/EntityModels';
import { ClusterPath, MaterialCluster } from '../../entities/EntityModels';

import { ROUTES } from '../routes';

@Component({
    selector: 'structure',
    templateUrl: './structure.html',
})
export class StructureComponent implements OnInit {
    material: Material;
    _typeMaterialUnits: TypeMaterialUnit[];
    _typeTSCAClasses: TypeTSCAClass[];
    _edm: EditManager<Material>;
    _clusterPath: ClusterPath;

    constructor(public _uow: UnitOfWork, public _userManager: UserManager, public _stateMap: StateMap,
                public _editorService: EditorService, public _molImageService: MolImageService,
                public _location: Location, public _router: Router, private changeDetectorRef: ChangeDetectorRef) {

    }

    ngOnInit() {
        LocationFns.setTab(this._location, 0);
        this.material = this._stateMap.currentMaterial;
        this._edm = this._editorService.createEditManager<Material>(this, 'Material');
        this._edm.onlyEntity = this.material;

        this._uow.typeMaterialUnitRepository.all().then(u => {
            this._typeMaterialUnits = u;
        });

        this._uow.typeTSCAClassRepository.all().then(r => {
            this._typeTSCAClasses = r;

        });
        this._molImageService.getBase32SmileUrl(this.material.materialId).then((url) => {
            this.material.base32SmileUrl = url;
        });

        this.fetchMaterialClusterPath();
    }

    fetchMaterialClusterPath() {
        const params = { materialId: this.material.materialId };
        this._uow.fetch('MaterialClusters/MaterialClusterByMaterialId', params).then(m => {
            if (m != null && m.length > 0) {
                this._clusterPath = m[0].clusterPath;
            }
        });
    }

    onSave() {
        // Save null instead of empty string
        if (this.material.secondRoundNCSComment != null) {
            if (this.isEmptyString(this.material.secondRoundNCSComment)) {
                this.material.secondRoundNCSComment = null;
            }
        }
        if (this.material.molecularWeight != null && this.material.typeMaterialUnitId != null) {
            if (!this.isValidString(this.material.typeMaterialUnitId) || this.material.typeMaterialUnitId.toLowerCase() == 'none') {
                this.material.typeMaterialUnitId = null;
            }
        }

        this._edm.onSaveCore()
            .then(() => {
                this._molImageService.getBase32SmileUrl(this.material.materialId).then((url) => {
                    this.material.base32SmileUrl = url;
                });
            });
    }

    addedToTransparencyListDate() {
        const lists = this.material.materialTransparencyLists;
        if ((!lists) || lists.length == 0) {
            return null;
        }
        const mtl = _.sortBy(lists, l => l.addedToList)[lists.length - 1];
        return UtilFns.formatDate(mtl.addedToList, 'YYYY');
    }

    isStaff() {
        return this._stateMap.currentUser.isStaff;
    }

    getSmileUrl(material: Material) {
        if (material.smiles == null) {
            return null;
        }
        const url = this._molImageService.getImageUrlForSmile(material.smiles);
        return url;
    }

    navToStructureSearch(id: number, smiles: string) {
        const parts = UtilFns.asRouterParts(ROUTES.Home, ROUTES.Home.childRoutes._SearchStructure, id, smiles);
        this._router.navigate(parts);
    }

    navToClusters(clusterPathId: number) {
        const parts = UtilFns.asRouterParts(ROUTES.Home, ROUTES.Home.childRoutes.MaterialClusters, this.material.materialId);
        this._router.navigate(parts);
    }

    hasSMILES() {
        if (this.material == null) { return false; }

        return (this.isValidString(this.material.smiles));
    }

    onCopySMILESToClipboard() {
        const smiles = this.material.smiles;

        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = smiles;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }

    onMolecularWeightChange() {
        if (this.material.molecularWeight == null) {
            this.material.typeMaterialUnitId = null;
        }
    }

    isEmptyString(teststring: string) {
        if (teststring == null) { return false; }

        return (teststring.trim().length == 0);
    }

    isValidString(teststring: string) {
        if (!teststring) { return false; }

        return (teststring.trim().length > 0);
    }

    checkSecondRoundNCS(value: boolean) {
        this.material.secondRoundNCS = value;
    }
}
