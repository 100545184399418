<div>
    <span class="page-title">{{pageTitle()}}</span>
</div>

<div *ngIf="isEditing() || isRemoving()">
    <span class="inline" style="margin-top: 12px;">
        <span class="page-subtitle bold">Organization Name: </span>
        <span class="page-subtitle left-indent bold" style="color: #337ab7;">{{_data.selectedReportingOrganization.organizationName}}</span>
    </span>

        <span class="inline">
        <span class="page-subtitle left-indent bold">Survey Year: </span>
        <span class="page-subtitle bold" style="color: #337ab7;">{{_data.selectedSurveyYear}}</span>
    </span>
</div>

<div class="row" style="margin-left: 10px;">
    <span class="alert-message item-message">{{_validationMessage}}</span>
</div>

<div class="row">
    <i *ngIf="_isLoading" class="fa fa-circle-o-notch fa-spin" style="font-size:38px; color: #337ab7;"></i>
</div>

<div *ngIf="isAdding()">
    <div>
        <div class="material-reported-use-filters-container" style="margin-left: 22px; margin-top: 12px; width: 75%;">

            <div class="material-reported-uses-grid-container" style="margin-left: 8px;">
                <span class="bold item-material-uses-reporting-organization-label">Reporting Organization</span>
                <span class="bold item-material-uses-survey-years-label">Survey Year</span>

                <select class="dropdown-toggle text-left background-white survey-list item-material-uses-reporting-organization" style="width: 320px !important;" (change)="filterOrganizationChange($event)"
                        [(ngModel)]="_filterOrganizationName">
                    <option *ngFor="let org of _data.reportingOrganizations; let ix = index" class="dropdown" [value]="org.organizationName">{{org.organizationName}}</option>
                </select>

                <select class="dropdown-toggle text-left background-white survey-list item-material-uses-survey-years" style="width: 140px !important;" [ngModel]="_filterSurveyYear"
                        (change)="filterSurveyYearChange($event)">
                    <option *ngFor="let year of _surveyYears; let ix = index" class="dropdown" [value]="year">{{year}}</option>
                </select>

                <span class="page-subtitle alert-message bold item-material-uses-edit-message">{{_userMessage}}</span>

            </div>
        </div>
    </div>
</div>

<div class="edit-material-reported-uses-grid-container" style="margin-left: 24px; margin-top: 24px; margin-bottom: 18px; background-color: #EAEAEA; padding: 8px; width: 75%; border: 1px solid lightgray;">

    <!--row 2-->
    <span class="bold item-edit-material-uses-materialId-label text-left">MaterialId:</span>
    <span class="item-edit-material-uses-materialId text-left bold" style="color: #337ab7;">{{_data.selectedMaterial.materialId}}</span>

    <span class="bold item-edit-material-uses-cas-label text-left">CAS #:</span>
    <span class="item-edit-material-uses-cas text-left">{{_data.selectedMaterial.realCASNumber}}</span>

    <span class="bold item-edit-material-uses-rifmId-label text-left">RIFM ID:</span>
    <span class="item-edit-material-uses-rifmId text-left">{{_data.selectedMaterial.formattedRIFMId}}</span>

    <!--row 3-->
    <span class="bold item-edit-material-uses-principal-name-label text-left">Principal Name:</span>
    <span class="item-edit-material-uses-principal-name text-left">{{_data.selectedMaterial.principalSynonymText()}}</span>

</div>

<div *ngIf="isEditing() || isAdding()" style="margin-bottom: 40px; width: 75%;">

    <span class="bold page-subtitle inline" style="margin-top: 14px; margin-left: 24px;">Reported Use by Geographic Region</span>

    <div class="row" style="margin-left: 12px; margin-top: 24px;">
        <!--buttons to add unreported regions -->
        <div *ngIf="canAddNewGeoRegion()">
            <span *ngFor="let mga of _unreportedTypeGeographicalAreas;" class="inline" style="margin-left: 4px;">
                <button type="button" class="inline btn btn-add-geo-area" style="width: 175px; color: white !important;" (click)="addSurveyReportedUse(mga.typeGeographicalAreaId)">
                    {{ 'Add ' +  mga.geographicalArea}}
                </button>
            </span>
        </div>

        <div class="row" style="margin-left: 12px; margin-top: 24px; border: 1px solid lightgray">
            <div *ngIf="_data.edm.entities != []">
                <div *ngFor="let sru of _data.edm.entities; let ix = index">
                    <div *ngIf="!sru.entityAspect.entityState.isDeleted()">
                        <div class="flex-container" [ngStyle]="{ 'background-color': rowColor(ix)}" style="width: 98%; margin-top: 18px;">
                            <div class="geo-flex-item">
                                <span class="bold survey-reported-use-geo-label" [ngStyle]="{ 'background-color': backgroundGeo(ix)}">
                                    {{sru.survey.typeGeographicalArea.geographicalArea}}
                                </span>
                            </div>
                            <div class="geo-flex-item inline" style="margin-top: 20px;">
                                <span class="bold">Reported Kgs:</span>
                            </div>
                            <div class="geo-flex-item">
                                <input type="number" class="inline text-right" style="border: 1px solid #4cae4c; width: 210px; margin-top: 8px;" [(ngModel)]="sru.kilogramsConsumed">
                            </div>
                            <div class="geo-flex-item">
                                <span class="bold inline" style="margin-top: 8px;">Staff Notes:</span>
                            </div>
                            <div class="geo-flex-item">
                                <textarea class="form-control" style="width: 320px; margin-top: 8px;"
                                    rows=2 [(ngModel)]="_dictNotes[sru.survey.typeGeographicalAreaId]">
                                </textarea>
                            </div>
                            <div class="geo-flex-item inline" style="margin-top: 20px;">
                                <a class="alert-message" style="margin-top: 8px;" (click)="removeSurveyReportedUse(sru)">Remove</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

