import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { provideParent, UnitOfWork } from '../../services/common';
import { EditPropParent } from '../../controls/common';

import { Author } from '../../entities/EntityModels';
import {StringDistance} from '../../entities/projections/StringDistance';
import {UtilFns} from '../../utils/util-fns';

@Component({
    selector: 'author-editor',
    templateUrl: './author-editor.html',
    providers: [provideParent(AuthorEditorComponent, EditPropParent)]
})
export class AuthorEditorComponent implements OnInit, EditPropParent {
    @Input() entity: Author;
    @Output() apply = new EventEmitter<Author>();

    public _authorNameStringDistances: StringDistance[];
    public _noAuthorMatchesMessage: string;

    public _isLoading = true;

    constructor(public _uow: UnitOfWork) {}

    ngOnInit() {

    }

    getError(propName: string) {
        return this.entity.getErrorFor(propName);
    }

    get utilFns() {
        return UtilFns;
    }

    canApplyAuthor() {
        return !this.entity.entityAspect.hasValidationErrors;
    }

    onApplyAuthor(author: Author) {
        if (!author) {
            this.entity.entityAspect.rejectChanges();
        }
        this.apply.emit(author);
    }

    isAdded() {
        return this.entity && this.entity.entityAspect.entityState.isAdded();
    }

    canCheckForSimilarAuthors(): boolean {
        return (this.entity != null && this.isValidString(this.entity.authorName));
    }

    canShowNoAuthorMatchesMessage(): boolean {
        return (this._authorNameStringDistances != null && this._authorNameStringDistances.length == 0 && this.isValidString(this._noAuthorMatchesMessage));
    }

    onCheckForSimilarAuthors() {

        this._noAuthorMatchesMessage = '';

        if (!this.isValidString(this.entity.authorName)) {
            return;
        }

        this._isLoading = true;

        if (this.isAdded()) {
            // tslint:disable-next-line:max-line-length
            this._uow.fetch('References/FetchAuthorNameStringDistance', { authorName: this.entity.authorName, distThreshold: 4,  pctThreshold: 50.00 }).then(r => {
                this._authorNameStringDistances = r;
                this._isLoading = false;

                if (this._authorNameStringDistances == null || this._authorNameStringDistances.length == 0) {
                    this._noAuthorMatchesMessage = 'No similar Author names found for ' + this.entity.authorName;
                }
            });
        } else { // in edit mode, exclude the name of the reporting organization being edited
            // tslint:disable-next-line:max-line-length
            this._uow.fetch('References/FetchAuthorNameStringDistancesExcludeById', { authorName: this.entity.authorName, distThreshold: 4,  pctThreshold: 50.00, authorId: this.entity.authorId }).then(r => {
                this._authorNameStringDistances = r;
                this._isLoading = false;

                if (this._authorNameStringDistances == null || this._authorNameStringDistances.length == 0) {
                    this._noAuthorMatchesMessage = 'No similar Author names found for ' + this.entity.authorName;
                }
            });
        }
    }

    hasCalculatedDistances(): boolean {
        return (this._authorNameStringDistances != null && this._authorNameStringDistances.length > 0);
    }

    isValidString(teststring: string) {

        if (!teststring) { return false; }

        return (teststring.trim().length > 0);
    }
}
