<div class="indent-top-sm"></div>
<editor-header [edm]="_edm" [femaRestricted]="true"></editor-header>


<div class="col-xs-2">
    <img class="img-structure" *ngIf="material.base32SmileUrl" [src]="material.base32SmileUrl" alt="[Structure Image not available]"/>
</div>
<div class="col-xs-6">
    <div class="row form-group">
        <label class="col-xs-3 text-left">Formula:</label>
        <!--<div *ngIf="!_edm.editing" class="col-xs-8">{{material.formula}}</div>-->
        <div *ngIf="!_edm.editing" class="col-xs-8 break-word" [chemFormula]="material.formula"></div>
        <textarea *ngIf="_edm.editing" class="col-xs-8 break-word" type="text" rows=2 [(ngModel)]="material.formula"></textarea>
        <span class="alert-danger">{{material.getErrorFor('formula')}}</span>
    </div>
    <div class="row form-group">
        <label class="col-xs-3 text-left">Molecular Weight:</label>
        <div *ngIf="!_edm.editing" class="col-xs-8">{{material.molecularWeightWithUnit}} </div>
        <input *ngIf="_edm.editing" class="col-xs-2" type="number" (change)="onMolecularWeightChange()" [(ngModel)]="material.molecularWeight">
        <select *ngIf="_edm.editing" class="col-xs-4" [ngModel]="material.typeMaterialUnitId" (change)="material.typeMaterialUnitId = $event.target.value"
                style="height: 26px; margin-left: 4px;">
            <option selected value>-- None --</option>
            <option *ngFor="let item of _typeMaterialUnits" [value]="item.typeMaterialUnitId">{{item.typeMaterialUnitId}}</option>
        </select>
        <span class="alert-danger">{{material.getErrorFor('molecularWeight')}}</span>
    </div>
    <div class="row form-group" style="margin-bottom: 6px;">
        <strong class="col-xs-3 text-left">Smiles Notation:</strong>
        <div *ngIf="!_edm.editing">
            <a class="break-word inline" style="width: auto; margin-left: 16px;" (click)="navToStructureSearch(material.materialId, material.smiles);">{{material.smiles}}</a>
            <button *ngIf="hasSMILES()" type="button" class="btn btn-copy-to-clipboard-transparent image-top" (click)="onCopySMILESToClipboard()" style="margin-top: -4px;"></button>
        </div>
        <input *ngIf="_edm.editing" class="col-xs-8" type="text" [(ngModel)]="material.smiles">
        <span class="alert-danger">{{material.getErrorFor('smiles')}}</span>
    </div>
    <div class="row form-group" *ngIf="!_edm.editing && material.smiles">
        <span class="col-xs-3 text-left"></span>
        <span class="col-xs-8 break-word italic" style="background-color: aliceblue;">This link will open the Structure Search page where the SMILES string will be converted to a structure for searching.</span>
    </div>
    <div class="row form-group">
        <strong class="col-xs-3 text-left">Generic Class:</strong>
        <div *ngIf="!_edm.editing" class="col-xs-8">{{material.typeTscaClass?.genericClass}} </div>
        <select *ngIf="_edm.editing" class="col-xs-8" [ngModel]="material.typeTSCAClassId" (change)="material.typeTSCAClassId=$event.target.value">
            <option disabled selected value>-- None --</option>
            <option *ngFor="let item of _typeTSCAClasses" [value]="item.typeTSCAClassId">{{item.genericClass}}</option>
        </select>
    </div>
    <div class="row form-group">
        <strong class="col-xs-3 text-left">Description:</strong>
        <div *ngIf="!_edm.editing" class="col-xs-6">{{material.materialDescription}} </div>
        <input *ngIf="_edm.editing" class="col-xs-6" type="text" [(ngModel)]="material.materialDescription">
        <span class="alert-danger">{{material.getErrorFor('materialDescription')}}</span>
    </div>
    <div class="row form-group">
        <strong class="col-xs-3 text-left">Functional Use:</strong>
        <div *ngIf="!_edm.editing" class="col-xs-8">{{material.functionalUseExt()}}</div>
        <input *ngIf="_edm.editing" class="col-xs-8" type="text" [(ngModel)]="material.functionalUse">
        <span class="alert-danger">{{material.getErrorFor('molecularWeight')}}</span>
    </div>
    <div class="row form-group" *ngIf="_edm.editing || material.materialNotes">
        <strong class="col-xs-3 text-left">Material Notes:</strong>
        <div *ngIf="!_edm.editing" class="col-xs-8">{{material.materialNotes}}</div>
        <input *ngIf="_edm.editing" class="col-xs-8" type="text" [(ngModel)]="material.materialNotes">
    </div>
    <div class="row form-group">
        <strong class="col-xs-3 text-left">Natural Occurrence:</strong>
        <div *ngIf="!_edm.editing" class="col-xs-8" [htmlGreek]="material.naturalOccurrence()"></div>
        <!--<input class="col-xs-7" type="text" [(ngModel)]="material.naturalOccurrence()">-->
    </div>

    <div *ngIf="_clusterPath" class="row form-group">
        <strong class="col-xs-3 text-left">Cluster Path:</strong>
        <a class="col-xs-8" (click)="navToClusters(_clusterPath.clusterPathId);">{{_clusterPath.clusterPathName}}</a>
    </div>

    <div class="row form-group">
        <strong class="col-xs-5 text-left" style="color: darkgrey;">Safety Assessment Information</strong>
    </div>

    <div class="row form-group" style="margin-top: 4px;">
        <strong class="col-xs-3 text-left">Second Round NCS:</strong>
        <input *ngIf="!_edm.editing" class="form-control-static mediumcheckbox ncs-checkbox" type="checkbox" disabled [checked]="material.secondRoundNCS">
        <input  *ngIf="_edm.editing" class="form-control-static mediumcheckbox ncs-checkbox" type="checkbox" (change)="checkSecondRoundNCS($event.target.checked)" [(ngModel)]="material.secondRoundNCS">
    </div>

    <div class="row form-group">
        <strong class="col-xs-3 text-left">Second Round NCS Comment:</strong>
        <div *ngIf="!_edm.editing" class="col-xs-8">{{material.secondRoundNCSComment || '-'}}</div>
        <input *ngIf="_edm.editing" class="col-xs-8" type="text" [(ngModel)]="material.secondRoundNCSComment">
    </div>

</div>
<div class="col-xs-4">
    <div style="margin-right: 38px">
        <div class="row form-group">
            <strong class="col-xs-4">Added to RIFM Database:</strong>
            <!--<div class="col-xs-7">{{material.created | date:'y'}}</div>-->
            <div class="col-xs-8">{{ _stateMap.utilFns.formatDate(material.created, 'YYYY')}}</div>
        </div>
        <div class="row form-group">
            <strong class="col-xs-4">Alternate CAS No.:</strong>
            <div *ngIf="!_edm.editing" class="col-xs-8">{{material.alternateCASNumber || '-'}} </div>
            <input *ngIf="_edm.editing" class="col-xs-8" type="text" [(ngModel)]="material.alternateCASNumber">
        </div>
        <div *ngIf="isStaff()" class="row form-group">
            <strong class="col-xs-4">Added to the Transparency List:</strong>
            <div class="col-xs-8">{{addedToTransparencyListDate() || '-'}}</div>
        </div>
        <div *ngIf="isStaff()" class="row form-group">
            <strong class="col-xs-4">No. Sample Avail:</strong>
            <div class="col-xs-8">{{material.noSampleAvail || '-'}}</div>
        </div>
        <div *ngIf="isStaff()" class="row form-group">
            <strong class="col-xs-4">No. Conc Surv Available:</strong>
            <div class="col-xs-8">{{material.noConcSurvAvail || '-'}}</div>
        </div>
        <div *ngIf="isStaff()" class="row form-group">
            <strong class="col-xs-4">Internal Material Notes:</strong>
            <div *ngIf="!_edm.editing" class="col-xs-8">{{material.internalMaterialNotes || '-'}} </div>
            <textarea *ngIf="_edm.editing" class="col-xs-8" type="text" rows=5 [(ngModel)]="material.internalMaterialNotes"></textarea>
            <span class="alert-danger">{{material.getErrorFor('internalMaterialNotes')}}</span>
        </div>
    </div>
</div>
