<div class="survey-use-filters-container">
    <div class="row" style="margin-top: 4px;">
        <div class="col-md-2">
            <span class="bold survey-filter-cols">Survey Year</span>
        </div>
        <div class="col-md-2">
            <span class="bold survey-filter-cols">Survey Material</span>
        </div>
        <div class="col-md-3">
            <span class="bold survey-filter-cols">Survey Companies</span>
        </div>
        <div class="col-md-3 survey-uom-type">
            <span class="bold">{{"Unit of Measure: " + _unitOfMeasure}}</span>
        </div>
    </div>

    <div class="row">
        <div class="col-md-2">
            <select [disabled]="_isLoading" class="dropdown-toggle survey-list text-left background-white" [(ngModel)]="_filterSurveyYear" (change)="filterSurveyYearChange($event)">
                <option *ngFor="let year of _surveyYears; let ix = index" class="dropdown" [value]="year">{{year}}</option>
            </select>
        </div>
        <div class="col-md-2">
            <div class="survey-filter-cols">
                <div class="input-group">
                    <div class="casnumber-and-rifmid background-white">
                        <span style="text-decoration: none !important;">{{_selectedCASDisplay}}</span>
                        <a  *ngIf="_selectedCASDisplay && _selectedCASDisplay != '' && !_isLoading" class="text-pointer remove-x" (click)="clearSelectedMaterial()">X</a>
                    </div>
                </div>
                <button [disabled]="_isLoading" type="button" class="btn btn-sm btn-primary select-survey-material-button" (click)="onSelectMaterial()">Select Material</button>
            </div>
        </div>
        <div class="col-md-3">
            <select class="dropdown-toggle survey-list text-left background-white" style="min-width: 220px; width: auto !important;" [disabled]="_isLoading" [(ngModel)]="_filterOrganizationName" (change)="filterOrganizationChange($event)">
                <option *ngFor="let name of _organizationNames; let ix = index" class="dropdown" [value]="name">{{name}}</option>
            </select>
        </div>
        <div class="col-md-3">
            <div style="margin-top: 4px;">
                <button class="btn btn-sm btn-primary" type="button" [disabled]="_isLoading" (click)="setUnitOfMeasure('Kilograms')">Kilograms</button>
                <button class="btn btn-sm btn-primary" type="button" [disabled]="_isLoading" (click)="setUnitOfMeasure('Metric Tons')">Metric Tons</button>
            </div>
        </div>

        <!--<div class="col-md-1">
            <button class="btn btn-primary btn-lg" type="button" (click)="generateReport()">Generate Report</button>
        </div>-->

    </div>
</div>

<hr>
<div class="row">
    <i *ngIf="_isLoading" class="fa fa-circle-o-notch fa-spin" style="font-size:38px; color: #337ab7; margin-left: 24px;"></i>
</div>

<h4 class="bold">{{_reportTitle}}</h4>
<div class="basic-pagination row" *ngIf="hasPages()">
    <span class="col-md-1 bold basic-pagination-label">Pages:</span>
    <ul class="col-md-10">
        <li *ngFor="let n of _pages; let ix=index">
            <button [ngClass]="{'basic-pagination-btn-active': isActive(n),  'basic-pagination-btn-inactive': isInactive(n)}" (click)="fetchNextPage(n)">{{n}}</button>
        </li>
    </ul>
</div>

<h5 *ngIf="hasLinks" class="bold">Click the CAS No. link to show the list of all companies reporting the material</h5>

<div *ngIf="_surveyReportedUseByMaterialAndYears && (_surveyReportedUseByMaterialAndYears.length > 0)" style="width: 75%">
    <dx-data-grid [dataSource]="_surveyReportedUseByMaterialAndYears"
                  [showBorders]="true"
                  [allowColumnReordering]="true"
                  [allowColumnResizing]="true">
        <dxo-paging [enabled]="false"></dxo-paging>
        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-state-storing [enabled]="true" type="localStorage" storageKey="_surveyReportedUseByMaterialAndYears"></dxo-state-storing>

        <dxi-column dataField="surveyYear" caption="Survey Year" alignment="center"></dxi-column>

        <dxi-column dataField="materialId" caption="MaterialId" alignment="center"></dxi-column>

        <dxi-column dataField="displayCAS" caption="CAS #" cellTemplate="displayCAS" alignment="center"></dxi-column>
        <div *dxTemplate="let cell of 'displayCAS'">
            <a (click)="onShowWhoUsedMost(cell.data)"> {{cell.data.displayCAS || '(No CAS#)' }}</a>
        </div>

        <dxi-column dataField="rIFMId" caption="RIFM ID" alignment="center"></dxi-column>

        <dxi-column dataField="china" [visible]="_filterSurveyYear == '2011'" caption="China" cellTemplate="china" alignment="center"></dxi-column>
        <div *dxTemplate="let cell of 'china'">
            <span *ngIf="_unitOfMeasure == 'Kilograms'">{{ utilFns.formatNumber(cell.data.china, 12, 3) }}</span>
            <span *ngIf="_unitOfMeasure == 'Metric Tons'">{{ utilFns.scaleNumber(cell.data.china, 1000, 10, 4) }}</span>
        </div>

        <dxi-column dataField="asiaPacific" [visible]="_filterSurveyYear != '2011'" caption="Asia-Pacific" cellTemplate="asiaPacific" alignment="center"></dxi-column>
        <div *dxTemplate="let cell of 'asiaPacific'">
            <span *ngIf="_unitOfMeasure == 'Kilograms'">{{ utilFns.formatNumber(cell.data.asiaPacific, 12, 3) }}</span>
            <span *ngIf="_unitOfMeasure == 'Metric Tons'">{{ utilFns.scaleNumber(cell.data.asiaPacific, 1000, 10, 4) }}</span>
        </div>

        <dxi-column dataField="japan" caption="Japan" [visible]="_filterSurveyYear == '2011' || _filterSurveyYear == '2023'" cellTemplate="japan" alignment="center"></dxi-column>
        <div *dxTemplate="let cell of 'japan'">
            <span *ngIf="_unitOfMeasure == 'Kilograms'">{{ utilFns.formatNumber(cell.data.japan, 12, 3) }}</span>
            <span *ngIf="_unitOfMeasure == 'Metric Tons'">{{ utilFns.scaleNumber(cell.data.japan, 1000, 10, 4) }}</span>
        </div>

        <dxi-column dataField="europe" caption="Europe" cellTemplate="europe" alignment="center"></dxi-column>
        <div *dxTemplate="let cell of 'europe'">
            <span *ngIf="_unitOfMeasure == 'Kilograms'">{{ utilFns.formatNumber(cell.data.europe, 12, 3) }}</span>
            <span *ngIf="_unitOfMeasure == 'Metric Tons'">{{ utilFns.scaleNumber(cell.data.europe, 1000, 10, 4) }}</span>
        </div>

        <dxi-column dataField="northAmerica" caption="North America" cellTemplate="northAmerica" alignment="center"></dxi-column>
        <div *dxTemplate="let cell of 'northAmerica'">
            <span *ngIf="_unitOfMeasure == 'Kilograms'">{{ utilFns.formatNumber(cell.data.northAmerica, 12, 3) }}</span>
            <span *ngIf="_unitOfMeasure == 'Metric Tons'">{{ utilFns.scaleNumber(cell.data.northAmerica, 1000, 10, 4) }}</span>
        </div>

        <dxi-column dataField="southAmerica" caption="South America" cellTemplate="southAmerica" alignment="center"></dxi-column>
        <div *dxTemplate="let cell of 'southAmerica'">
            <span *ngIf="_unitOfMeasure == 'Kilograms'">{{ utilFns.formatNumber(cell.data.southAmerica, 12, 3) }}</span>
            <span *ngIf="_unitOfMeasure == 'Metric Tons'">{{ utilFns.scaleNumber(cell.data.southAmerica, 1000, 10, 4) }}</span>
        </div>

        <dxi-column dataField="other" caption="Other" cellTemplate="other" alignment="center"></dxi-column>
        <div *dxTemplate="let cell of 'other'">
            <span *ngIf="_unitOfMeasure == 'Kilograms'">{{ utilFns.formatNumber(cell.data.other, 12, 3) }}</span>
            <span *ngIf="_unitOfMeasure == 'Metric Tons'">{{ utilFns.scaleNumber(cell.data.other, 1000, 10, 4) }}</span>
        </div>
    </dx-data-grid>
</div>

<div *ngIf="_surveyReportedUseByCompany && (_surveyReportedUseByCompany.length > 0)" style="width: 75%">
    <dx-data-grid [dataSource]="_surveyReportedUseByCompany"
                  [showBorders]="true"
                  [allowColumnReordering]="true"
                  [allowColumnResizing]="true">
        <dxo-paging [enabled]="false"></dxo-paging>
        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-state-storing [enabled]="true" type="localStorage" storageKey="_surveyReportedUseByCompany"></dxo-state-storing>

        <dxi-column dataField="surveyYear" caption="Survey Year" alignment="center"></dxi-column>
        <dxi-column dataField="organizationName" caption="Organization Name"></dxi-column>

        <dxi-column dataField="materialId" caption="MaterialId" alignment="center"></dxi-column>

        <dxi-column dataField="displayCAS" caption="CAS #" cellTemplate="displayCAS" alignment="center"></dxi-column>
        <div *dxTemplate="let cell of 'displayCAS'">
            <a (click)="onShowWhoUsedMost(cell.data)"> {{cell.data.displayCAS}}</a>
        </div>

        <dxi-column dataField="rIFMId" caption="RIFM ID" alignment="center"></dxi-column>

        <dxi-column dataField="china" [visible]="_filterSurveyYear == '2011'" caption="China" cellTemplate="china" alignment="center"></dxi-column>
        <div *dxTemplate="let cell of 'china'">
            <span *ngIf="_unitOfMeasure == 'Kilograms'">{{ utilFns.formatNumber(cell.data.china, 12, 3) }}</span>
            <span *ngIf="_unitOfMeasure == 'Metric Tons'">{{ utilFns.scaleNumber(cell.data.china, 1000, 10, 4) }}</span>
        </div>

        <dxi-column dataField="asiaPacific" [visible]="_filterSurveyYear != '2011'" caption="Asia-Pacific" cellTemplate="asiaPacific" alignment="center"></dxi-column>
        <div *dxTemplate="let cell of 'asiaPacific'">
            <span *ngIf="_unitOfMeasure == 'Kilograms'">{{ utilFns.formatNumber(cell.data.asiaPacific, 12, 3) }}</span>
            <span *ngIf="_unitOfMeasure == 'Metric Tons'">{{ utilFns.scaleNumber(cell.data.asiaPacific, 1000, 10, 4) }}</span>
        </div>

        <dxi-column dataField="japan" caption="Japan" [visible]="_filterSurveyYear == '2011' || _filterSurveyYear == '2023'" cellTemplate="japan" alignment="center"></dxi-column>
        <div *dxTemplate="let cell of 'japan'">
            <span *ngIf="_unitOfMeasure == 'Kilograms'">{{ utilFns.formatNumber(cell.data.japan, 12, 3) }}</span>
            <span *ngIf="_unitOfMeasure == 'Metric Tons'">{{ utilFns.scaleNumber(cell.data.japan, 1000, 10, 4) }}</span>
        </div>

        <dxi-column dataField="europe" caption="Europe" cellTemplate="europe" alignment="center"></dxi-column>
        <div *dxTemplate="let cell of 'europe'">
            <span *ngIf="_unitOfMeasure == 'Kilograms'">{{ utilFns.formatNumber(cell.data.europe, 12, 3) }}</span>
            <span *ngIf="_unitOfMeasure == 'Metric Tons'">{{ utilFns.scaleNumber(cell.data.europe, 1000, 10, 4) }}</span>
        </div>

        <dxi-column dataField="northAmerica" caption="North America" cellTemplate="northAmerica" alignment="center"></dxi-column>
        <div *dxTemplate="let cell of 'northAmerica'">
            <span *ngIf="_unitOfMeasure == 'Kilograms'">{{ utilFns.formatNumber(cell.data.northAmerica, 12, 3) }}</span>
            <span *ngIf="_unitOfMeasure == 'Metric Tons'">{{ utilFns.scaleNumber(cell.data.northAmerica, 1000, 10, 4) }}</span>
        </div>

        <dxi-column dataField="southAmerica" caption="South America" cellTemplate="southAmerica" alignment="center"></dxi-column>
        <div *dxTemplate="let cell of 'southAmerica'">
            <span *ngIf="_unitOfMeasure == 'Kilograms'">{{ utilFns.formatNumber(cell.data.southAmerica, 12, 3) }}</span>
            <span *ngIf="_unitOfMeasure == 'Metric Tons'">{{ utilFns.scaleNumber(cell.data.southAmerica, 1000, 10, 4) }}</span>
        </div>

        <dxi-column dataField="other" caption="Other" cellTemplate="other" alignment="center"></dxi-column>
        <div *dxTemplate="let cell of 'other'">
            <span *ngIf="_unitOfMeasure == 'Kilograms'">{{ utilFns.formatNumber(cell.data.other, 12, 3) }}</span>
            <span *ngIf="_unitOfMeasure == 'Metric Tons'">{{ utilFns.scaleNumber(cell.data.other, 1000, 10, 4) }}</span>
        </div>
    </dx-data-grid>
</div>
<survey-whousedmost></survey-whousedmost>
<material-selector></material-selector>


