<h4 *ngIf="isAdded()" class="bold">Adding new Author</h4>
<h4 *ngIf="!isAdded()" class="bold">Editing existing Author</h4>
<span>
    <button type="button" class="btn btn-sm btn-primary" [disabled]="!canApplyAuthor()"  (click)="onApplyAuthor(entity)">Apply</button>            
    <button type="button" class="btn btn-sm btn-primary"  (click)="onApplyAuthor(null)">Cancel</button>
</span>

<div class="form-horizontal">

    <edit-prop label="Author Last Name or Company Name" prop="authorName" szs="3,4">
        <input class="form-control" type="text" [(ngModel)]="entity.authorName">
        <button *ngIf="canCheckForSimilarAuthors()" type="button" class="btn btn-sm btn-success" style="margin: 0px !important; margin-top: 4px !important;" (click)="onCheckForSimilarAuthors()">Check for Similar Authors</button>
    </edit-prop>

    <edit-prop label="Initials" prop="initials" szs="3,4">
        <input class="form-control" type="text" [(ngModel)]="entity.initials">
    </edit-prop>

    <div class="float-left" style="margin-top: 38px;">
        <div *ngIf="hasCalculatedDistances()">
            <h6 class="page-subtitle bold">{{'These Author Names are similar to ' + entity.authorName}}</h6>
            <table class="table table-striped table-bordered table-condensed small" style="width:auto;">
                <thead style="background-color: #C1FFC1; color: #000000;">
                <tr>
                    <td>Author Name</td>
                    <td>Author Initials</td>
                    <td>Difference Score</td>
                    <td>Percent Difference</td>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let dist of _authorNameStringDistances">
                    <td>{{dist.comparedString}}</td>
                    <td>{{dist.additionalData}}</td>
                    <td>{{dist.distance}}</td>
                    <td>{{utilFns.formatNumber(dist.percentDifference, 3,  2)}}</td>
                </tr>
                </tbody>
            </table>
        </div>

        <p class="bold page-subtitle">{{_noAuthorMatchesMessage}}</p>
    </div>
</div>
